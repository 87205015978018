import React from 'react'
import GooglePlay from '../assets/img/playstore.png';
import AppStore from '../assets/img/appstore.png';

export default function Buttons() {
    return (
        <div className="buttonsContainer">
            <h2 className="title">Coming Soon On</h2>
            <div className="googlePlay">
                <a  href="https://play.google.com/store"><img src={GooglePlay} alt="googleplayicon"/></a>
            </div>
            <div className="appStore">
                <a  href="https://www.apple.com/ios/app-store/"><img src={AppStore} alt="appstoreicon"/></a>
            </div>
        </div>
    )
}
