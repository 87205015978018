import React from 'react';
import {Carousel} from '3d-react-carousal';
import Logo from '../src/components/Logo';
import Buttons from '../src/components/Buttons';
import ImageOne from '../src/assets/img/Splash-Screen.png';
import ImageTwo from '../src/assets/img/Daashboard.png';
import ImageThree from '../src/assets/img/Video.png';
import ImageFour from '../src/assets/img/NRC.png';
import ImageFive from '../src/assets/img/Recruitment.png';
import ImageSix from '../src/assets/img/Social.png';
import ImageSeven from '../src/assets/img/Shop.png';
import ImageEight from '../src/assets/img/Personal-Trainer.png';
import ImageNine from '../src/assets/img/Live-Score.png';
import '../src/assets/css/styles.css';


export default function App() {
  let slides = [
    <img  src={ImageOne} alt="1" />,
    <img  src={ImageTwo} alt="2" />,
    <img  src={ImageThree} alt="3" />,  
    <img  src={ImageFour} alt="4" />,  
    <img  src={ImageFive} alt="5" />,  
    <img  src={ImageSix} alt="6" />,  
    <img  src={ImageSeven} alt="7" />,
    <img  src={ImageEight} alt="8" />,
    <img  src={ImageNine} alt="9" />
  ];
  let slideTexts = [
    "The Next Evolution of Youth Athletic Training and Recruiting", 
    "Create your Own Personal Profile and Build a Digital Resume for your Future Recruitment",
    "Capture, Store, Analyse and Provide Real Time Recommendations to help Perfect your Mechanics with the Video Analysis Tool",
    "Rank Yourself Against Other Athletes at a Regional, State and National Sports Level",
    "Utilize our Recruiting Tool to search out your future University.",
    "Build Relationships with like minded athletes across the country.",
    "Titan Sports Lab Shop Slides",
    "Prevent Injury's with Your Physical Development Coach",
    "Get Live Score Updates for other Games in your Region.",
  ]
  return (
    <div className="appContainer">
      <div className="container">
        <Logo/>
        <div className="contentWrapper clearfix">
          <Carousel slideTexts={slideTexts} slides={slides}/>
        </div> 
        <Buttons/>
      </div>
    </div>
  );
}


