import React from 'react';
import LogoImage from '../assets/img/logo.png';

export default function Logo() {
    return (
        <div className="logoContainer">
            <a href="/"><img src={LogoImage} alt="logo"/></a>
        </div>
    )
}
